import React from 'react'
import { FaLinkedinIn } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/vijaycaveripakkam/',
    TeamMemberName: 'Vijay Caveripakkam',
    DECHeading: 'CEO',
    DECPara: 'Entrepreneur and visionary thought leader.  Deep experience in building and scaling global companies.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/RaindropTeamVijayPic.jpg',
    AltTag: 'VijayImage',
  },
  {
    id: '2',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/wardkarson/',
    TeamMemberName: 'Ward Karson',
    DECHeading: 'COO',
    DECPara: 'Entrepreneur.  Procurement industry leader and subject matter expert.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/wardPicBio.png',
    AltTag: 'WordImage',
  },
  {
    id: '3',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/john-moore-528223/',
    TeamMemberName: 'John Moore',
    DECHeading: 'Head of Development',
    DECPara:
      'Software design and development executive leader.  Builder of world-class industrial-grade enterprise software.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/RaindropTeamJohnPic.jpg',
    AltTag: 'JohnImage',
  },
  {
    id: '4',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/rohita-joshi-29b10a1/',
    TeamMemberName: 'Rohita Joshi',
    DECHeading: 'Customer Success',
    DECPara: 'Entrepreneur.  Executive focused on creating equality and diversity in the workforce.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/RaindropTeamRohitaPic.jpg',
    AltTag: 'RohitaImage',
  },
  {
    id: '5',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/davidmcravens/',
    TeamMemberName: 'David Cravens',
    DECHeading: 'VP Business Development',
    DECPara:
      'Veteran Sales leader with extensive expertise in Strategic Sourcing and transactional Procurement solutions.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/RaindropTeamDavePic.jpg',
    AltTag: 'DaveImage',
  },
  {
    id: '6',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/shannonkreps/',
    TeamMemberName: 'Shannon Kreps',
    DECHeading: 'VP Marketing',
    DECPara: 'Lover of Procurement and Data.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/Shannon-Kreps.png',
    AltTag: 'ShannonImage',
  },
  {
    id: '7',
    icon: <FaLinkedinIn color="#007ab9" size="45" />,
    ProfileUrl: 'https://www.linkedin.com/in/nabil-tamer-958aa13/',
    TeamMemberName: 'Nabil Tamer',
    DECHeading: 'Product Management',
    DECPara: 'Artificial Intelligence and Machine Learning thought leader of world-class software.',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/RaindropTeamNabilPic.jpg',
    AltTag: 'NabilImage',
  },
]
